import React from 'react'
import Main from './containers/Main'
function index() {
  return (
    <div>
      <Main/>
    </div>
  )
}

export default index
