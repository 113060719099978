import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Social from './components/social';
import Forum from './components/forum';
function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Forum />} />
            </Routes>
        </Router>
    );
}
export default App;
